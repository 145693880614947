import BaseBean from "@/utils/BaseBean";

export interface ITtPayListDataObj {
    utilInst:TtPayListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class TtPayListUtil extends BaseBean{
    public dataObj:ITtPayListDataObj

    constructor(proxy:any,dataObj:ITtPayListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
    }
}